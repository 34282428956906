export type PlayerMap = Record<PlayerId, Player>;
export type PlayerAngles = Record<PlayerId, number>;
export type CardMap = Record<CardId, Card>;
export type HandMap = Record<PlayerId, CardId[]>;

export const GAMES = '__games__';

export interface GameState {
  players: PlayerMap;
  cards: CardMap;
  cardOrder: CardId[];
}

export interface Player {
  id: PlayerId;
  name: string;
  position: number;
}

export interface Card {
  id: CardId;
  faceUp: boolean;
  selected?: PlayerId | null;
  hovered?: PlayerId | null;
  inHand?: HandState | null;
  revealed?: HandReveal | null;
  position: Point;
  rotation: number;
}

export type RotateDirection = 'right' | 'left';
export type CardUpdateProperty = 'faceUp' | 'selected' | 'hovered' | 'inHand' | 'revealed' | 'position' | 'rotation';
export type CardUpdate = Partial<Omit<Card, 'id'>>;
export interface CardPropsUpdate {
  cards: CardId[];
  update: CardUpdate;
}

export interface HandState {
  playerId: PlayerId;
  position: number;
}

export interface HandReveal {
  cards: CardId[];
  playerId?: PlayerId;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Rect {
  x: number;
  y: number;
  height: number;
  width: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface SelectionBox {
  x: number;
  y: number;
  width: number;
  height: number;
  startX: number;
  startY: number;
  mouseX: number;
  mouseY: number;
}

export interface CardProperties {
  position: Point;
  rotation: number;
}

export interface RouteParams {
  gameid: string;
}

export type PlayerId = string;
export type CardId =
  | string
  | 'c2'
  | 'c3'
  | 'c4'
  | 'c5'
  | 'c6'
  | 'c7'
  | 'c8'
  | 'c9'
  | 'c10'
  | 'cj'
  | 'cq'
  | 'ck'
  | 'ca'
  | 'd2'
  | 'd3'
  | 'd4'
  | 'd5'
  | 'd6'
  | 'd7'
  | 'd8'
  | 'd9'
  | 'd10'
  | 'dj'
  | 'dq'
  | 'dk'
  | 'da'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 'h9'
  | 'h10'
  | 'hj'
  | 'hq'
  | 'hk'
  | 'ha'
  | 's2'
  | 's3'
  | 's4'
  | 's5'
  | 's6'
  | 's7'
  | 's8'
  | 's9'
  | 's10'
  | 'sj'
  | 'sq'
  | 'sk'
  | 'sa';

export enum Cards {
  C2 = 'c2',
  C3 = 'c3',
  C4 = 'c4',
  C5 = 'c5',
  C6 = 'c6',
  C7 = 'c7',
  C8 = 'c8',
  C9 = 'c8',
  C10 = 'c10',
  CJ = 'cj',
  CQ = 'cq',
  CK = 'ck',
  CA = 'ca',
  D2 = 'd2',
  D3 = 'd3',
  D4 = 'd4',
  D5 = 'd5',
  D6 = 'd6',
  D7 = 'd7',
  D8 = 'd8',
  D9 = 'd8',
  D10 = 'd10',
  DJ = 'dj',
  DQ = 'dq',
  DK = 'dk',
  DA = 'da',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  H7 = 'h7',
  H8 = 'h8',
  H9 = 'h8',
  H10 = 'h10',
  HJ = 'hj',
  HQ = 'hq',
  HK = 'hk',
  HA = 'ha',
  S2 = 's2',
  S3 = 's3',
  S4 = 's4',
  S5 = 's5',
  S6 = 's6',
  S7 = 's7',
  S8 = 's8',
  S9 = 's8',
  S10 = 's10',
  SJ = 'sj',
  SQ = 'sq',
  SK = 'sk',
  SA = 'sa',
}
