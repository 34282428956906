import { CompoundAction } from 'redoodle';
import { IAppState } from '../store/app';
import { SetCardWidth, SetWindowSize } from '../store/player';
import { Store } from 'redux';
import debounce from 'lodash.debounce';

export class WindowService {
  public static store: Store<IAppState>;
  public static instance: WindowService;
  public static height: number;
  public static width: number;
  public static cardWidth: number;

  public static init(store: Store) {
    this.instance = new WindowService(store);
    WindowService.setDimensions(window.innerWidth, window.innerHeight);
    WindowService.listenToWindowChanges();
  }

  private static cardWidthFromWindow(windowWidth: number, windowHeight: number) {
    const minDimension = Math.min(windowWidth, windowHeight);
    const maxDimension = Math.max(windowWidth, windowHeight);

    return Math.round(0.12 * minDimension + 0.001 * maxDimension);
  }

  private static setDimensions(windowWidth: number, windowHeight: number) {
    const cardWidth = WindowService.cardWidthFromWindow(windowWidth, windowHeight);
    WindowService.width = windowWidth;
    WindowService.height = windowHeight;
    WindowService.cardWidth = cardWidth;

    WindowService.store.dispatch(
      CompoundAction([
        SetCardWidth.create(cardWidth),
        SetWindowSize.create({ width: windowWidth, height: windowHeight }),
      ])
    );
  }

  private static listenToWindowChanges() {
    window.addEventListener(
      'resize',
      debounce(() => {
        WindowService.setDimensions(window.innerWidth, window.innerHeight);
      }, 150)
    );
  }

  private constructor(store: Store) {
    WindowService.instance = this;
    WindowService.store = store;
    WindowService.width = window.innerWidth;
    WindowService.height = window.innerHeight;
    WindowService.cardWidth = WindowService.cardWidthFromWindow(window.innerWidth, window.innerHeight);
  }
}
