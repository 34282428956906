import { FirebaseService } from './services/FirebaseService';
import { Provider } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { WindowService } from './services/WindowService';
import { configureStore } from './store/configureStore';
import { theme } from './common/theme';
import Game from './game/Game';
import Home from './home/Home';
import React from 'react';

const store = configureStore();
FirebaseService.init(store);
WindowService.init(store);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/:gameid" exact component={Game} />
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
