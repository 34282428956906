import { AnimatePresence, motion } from 'framer-motion';
import { Card, CardId, Rect } from '../common/types';
import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import { cardToFront, getPlayerPositionAndRotation, uniq } from '../common/utils';
import { dragCardSelector, isDraggingSelector, myHandSelector, playerPositionSelector } from '../store/player';
import { numPlayersSelector, selectedCardsSelector } from '../store/game';
import { usePrevious } from '../common/hooks';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './Hand.module.scss';

const handWidth = 240;
const handHeight = 100;

export function getHandRect(playerPosition: number, numPlayers: number): Rect {
  const position = getPlayerPositionAndRotation(playerPosition, playerPosition, numPlayers, handWidth, handHeight, 0);

  return {
    x: position.x,
    y: position.y,
    width: handWidth,
    height: handHeight,
  };
}

export const Hand: React.FC = () => {
  const handRef = useRef<null | HTMLDivElement>(null);
  const isDragging = useSelector<IAppState, boolean>(isDraggingSelector);
  const dragCard = useSelector<IAppState, Card | undefined>(dragCardSelector);
  const dragCardOverHand = useSelector<IAppState, boolean>(state => state.player.dragCardOverHand);
  const selectedCards = useSelector<IAppState, CardId[]>(selectedCardsSelector);
  const hand = useSelector<IAppState, CardId[]>(myHandSelector);
  const playerPosition = useSelector<IAppState, number>(playerPositionSelector);
  const numPlayers = useSelector<IAppState, number>(numPlayersSelector);
  const position = getPlayerPositionAndRotation(playerPosition, playerPosition, numPlayers, handWidth, handHeight, 0);

  // drag end
  const prevIsDragging: boolean = usePrevious(isDragging);
  const prevDragCard: Card | undefined = usePrevious(dragCard);
  useEffect(() => {
    if (prevDragCard && prevIsDragging && !isDragging) {
      const cards = uniq(cardToFront(prevDragCard.id, selectedCards));
      if (dragCardOverHand) {
        if (!prevDragCard?.inHand) {
          FirebaseService.addCardsToHand(cards);
        }
      } else {
        FirebaseService.removeCardsFromHand(cards.filter(cardId => hand && hand.includes(cardId)));
      }
    }
  }, [prevIsDragging, isDragging, dragCard, prevDragCard, dragCardOverHand, selectedCards, hand]);

  return (
    <div className={styles.hand}>
      <AnimatePresence>
        {isDragging && !dragCard?.inHand && (
          <motion.div
            initial={{ opacity: 0, y: handHeight }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: handHeight }}
            style={{ x: position.x }}
            className={styles.dropTarget}
            ref={handRef}
          >
            <div className={classNames(styles.container, { [styles.intersecting]: dragCardOverHand })}>
              <div className={styles.label}>Place in Hand</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Hand;
