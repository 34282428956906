import { Menu } from '@material-ui/core';
import { Point } from 'framer-motion';
import DealMenu from './DealMenu';
import React from 'react';

interface Props {
  position?: Point;
  onClose: () => void;
}

export const ContextMenu: React.FC<Props> = ({ position, onClose }) => {
  return (
    <Menu
      keepMounted
      open={!!position}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={position ? { top: position.y, left: position.x } : undefined}
    >
      <DealMenu onClose={onClose} />
    </Menu>
  );
};

export default ContextMenu;
