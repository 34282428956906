import { CompoundAction } from 'redoodle';
import { FirebaseService } from '../services/FirebaseService';
import { GlobalHotKeys } from 'react-hotkeys';
import { IAppState } from '../store/app';
import { PlayerId, RotateDirection, SelectionBox } from '../common/types';
import {
  ResetHighlightedCards,
  SetHidePlayerName,
  SetShowInstructions,
  UpdateHighlightedCards,
  highlightedCardsSelector,
  playerIdSelector,
  playerPositionSelector,
} from '../store/player';
import { WindowService } from '../services/WindowService';
import { cardIsLocked, doPolygonsIntersect, getColor, rectToPolygon, rectToRotatedPolygon } from '../common/utils';
import { selectedCardsSelector } from '../store/game';
import { useDispatch, useSelector, useStore } from 'react-redux';
import CardCount from './CardCount';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import pick from 'lodash.pick';
import styles from './TableSelection.module.scss';

interface Props {
  tableRef: MutableRefObject<null | HTMLDivElement>;
  cardRefs: Record<string, MutableRefObject<HTMLDivElement | null>>;
}

const keyMap = {
  SHIFT_DOWN: { sequence: 'shift', action: 'keydown' },
  SHIFT_UP: { sequence: 'shift', action: 'keyup' },
  ARROW_RIGHT: { sequence: 'right', action: 'keydown' },
  ARROW_LEFT: { sequence: 'left', action: 'keydown' },
  TAB: { sequence: 'tab' },
  ESC: { sequence: 'esc' },
};

export const TableSelection: React.FC<Props> = ({ children, tableRef, cardRefs }) => {
  const playerId = useSelector<IAppState, PlayerId>(playerIdSelector);
  const playerPosition = useSelector<IAppState, number>(playerPositionSelector);
  const dispatch = useDispatch();
  const store = useStore<IAppState>();

  const [selectionBox, setSelectionBox] = useState<SelectionBox | undefined>(undefined);
  const [shift, setShift] = useState<boolean>(false);

  const rotateCard = useCallback(
    (direction: RotateDirection) => {
      const selectedCards = selectedCardsSelector(store.getState());
      const cards = store.getState().game.cards;
      const dragCardId = store.getState().player.dragCardId;
      const cardsToUpdate = dragCardId ? [dragCardId].concat(selectedCards) : selectedCards;
      FirebaseService.updateCards(cardsToUpdate, { rotation: direction === 'right' ? 90 : -90 }, (value, index) => ({
        rotation: cards[cardsToUpdate[0]].rotation + value.rotation,
      }));
    },
    [store]
  );

  const keyHandlers = useMemo(
    () => ({
      SHIFT_DOWN: () => setShift(true),
      SHIFT_UP: () => setShift(false),
      ARROW_RIGHT: () => rotateCard('right'),
      ARROW_LEFT: () => rotateCard('left'),
      TAB: (e: any) => {
        e.preventDefault();
        rotateCard('right');
      },
      ESC: (e: any) => {
        dispatch(SetShowInstructions.create(false));
      },
    }),
    [setShift, rotateCard, dispatch]
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.shiftKey) {
        setSelectionBox({
          x: e.clientX - (tableRef.current?.getBoundingClientRect().x || 0),
          y: e.clientY,
          startX: e.clientX - (tableRef.current?.getBoundingClientRect().x || 0),
          startY: e.clientY,
          height: 0,
          width: 0,
          mouseX: e.clientX,
          mouseY: e.clientY,
        });
      }
    },
    [tableRef]
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (selectionBox) {
        const x =
          e.clientX - (tableRef.current?.getBoundingClientRect().x || 0) < selectionBox.startX
            ? e.clientX - (tableRef.current?.getBoundingClientRect().x || 0)
            : selectionBox.startX;
        const y = e.clientY < selectionBox.startY ? e.clientY : selectionBox.startY;

        const newSelectionBox = {
          ...selectionBox,
          x,
          y,
          width: Math.abs(e.clientX - (tableRef.current?.getBoundingClientRect().x || 0) - selectionBox.startX),
          height: Math.abs(e.clientY - selectionBox.startY),
          mouseX: e.clientX,
          mouseY: e.clientY,
        };

        const state = store.getState();
        const cards = state.game.cards;
        const highlightedCards = state.player.highlightedCards;
        const updates: any[] = [];

        Object.keys(cards).forEach(cardId => {
          const card = cards[cardId];

          if (selectionBox && !cardIsLocked(card, playerId)) {
            const cardRect = cardRefs[cardId]?.current?.getBoundingClientRect();
            const intersecting = cardRect
              ? doPolygonsIntersect(rectToPolygon(selectionBox), rectToRotatedPolygon(cardRect, card.rotation))
              : false;

            if (!highlightedCards[cardId] && intersecting) {
              updates.push(UpdateHighlightedCards.create({ [cardId]: true }));
            } else if (highlightedCards[cardId] && !intersecting) {
              updates.push(UpdateHighlightedCards.create({ [cardId]: false }));
            }
          }
        });

        if (updates.length) dispatch(CompoundAction(updates));
        setSelectionBox(newSelectionBox);
      }
      const hidePlayerName = store.getState().player.hidePlayerName;
      if (
        e.clientY > (3 / 4) * WindowService.height &&
        e.clientX > (1 / 3) * WindowService.width &&
        e.clientX < (2 / 3) * WindowService.width
      ) {
        if (!hidePlayerName) {
          dispatch(SetHidePlayerName.create(true));
        }
      } else if (hidePlayerName) {
        dispatch(SetHidePlayerName.create(false));
      }
    },
    [store, dispatch, playerId, selectionBox, tableRef, cardRefs]
  );

  const onMouseUp = useCallback(() => {
    if (selectionBox) {
      setSelectionBox(undefined);
      const highlightedCards = highlightedCardsSelector(store.getState());
      FirebaseService.updateCards(highlightedCards, { selected: playerId });
      dispatch(ResetHighlightedCards.create());
    }
  }, [store, selectionBox, dispatch, playerId]);

  const onClickBg = useCallback(() => {
    const selectedCards = selectedCardsSelector(store.getState());
    if (selectedCards.length) {
      FirebaseService.updateCards(selectedCards, { selected: null });
    }
  }, [store]);

  return (
    <GlobalHotKeys keyMap={keyMap as any} handlers={keyHandlers}>
      <div
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        className={classNames(styles.selectionArea, { [styles.shift]: shift })}
      >
        <div className={styles.selectionBg} onMouseDown={onClickBg} />
        {children}
        <svg className={styles.selection}>
          <rect
            {...pick(selectionBox, ['x', 'y', 'width', 'height'])}
            className={styles.selectionBox}
            style={{ stroke: getColor(playerPosition) }}
          />
        </svg>
        <CardCount />
      </div>
    </GlobalHotKeys>
  );
};

export default TableSelection;
