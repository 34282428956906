import { AnimatePresence, motion } from 'framer-motion';
import { IconButton, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import classNames from 'classnames';
import styles from './Dialog.module.scss';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const Dialog: React.FC<Props> = ({ title, isOpen, onClose, children }) => {
  const isSm = useMediaQuery('(max-width:500px)');

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{
            x: isSm ? 0 : '-50%',
            y: isSm ? 0 : 'calc(-50% - 100px)',
            opacity: 0,
          }}
          animate={{
            x: isSm ? 0 : '-50%',
            y: isSm ? 0 : '-50%',
            opacity: 1,
          }}
          exit={{
            x: isSm ? 0 : '-50%',
            y: isSm ? 0 : 'calc(-50% + 1000px)',
            opacity: -1,
            rotate: Math.round(Math.random() * 20 - 10),
            transition: { type: 'tween', ease: [0.11, 0, 0.5, 0], duration: 0.4 },
          }}
          className={classNames(styles.container, { [styles.small]: isSm })}
        >
          <IconButton className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography className={styles.title}>{title}</Typography>
          <div className={styles.content}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dialog;
