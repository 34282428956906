import { AnimatePresence, motion } from 'framer-motion';
import { Button, Menu, Typography } from '@material-ui/core';
import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import { SetShowInstructions } from '../store/player';
import { useDispatch, useSelector } from 'react-redux';
import CopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import DealMenu from './DealMenu';
import HelpIcon from '@material-ui/icons/Help';
import Instructions from './Instructions';
import React, { useEffect, useState } from 'react';
// import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import styles from './Controls.module.scss';

interface Props {}

export const Controls: React.FC<Props> = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const gameId = useSelector<IAppState, string>(state => state.game.gameId);
  const showInstructions = useSelector<IAppState, boolean>(state => state.player.showInstructions);
  const dispatch = useDispatch();
  const { href } = window.location;

  function onCopy() {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }

  function openDealMenu(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  function closeInstructions() {
    dispatch(SetShowInstructions.create(false));
  }

  useEffect(() => {
    if (!localStorage.getItem('instructions_seen')) {
      setTimeout(() => {
        dispatch(SetShowInstructions.create(true));
        localStorage.setItem('instructions_seen', 'true');
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.controlsLeft}>
        <Typography variant="body1" className={styles.gameCode}>
          Game ID: {gameId}
        </Typography>
      </div>
      <div className={styles.controlsRight}>
        <CopyToClipboard text={href} onCopy={onCopy}>
          <Button
            className={classNames(styles.button, styles.copyButton)}
            startIcon={<CopyIcon fontSize="small" className={styles.copyIcon} />}
            variant="outlined"
          >
            <span className={styles.buttonText}>
              {!copied && 'Invite Friends'}
              {copied && 'Link Copied!'}
            </span>
          </Button>
        </CopyToClipboard>
        <Button className={styles.button} variant="outlined" onClick={() => FirebaseService.resetAllCards()}>
          Shuffle
        </Button>
        <Button className={styles.button} variant="outlined" onClick={openDealMenu}>
          Deal
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <DealMenu onClose={() => setAnchorEl(null)} alignRight />
        </Menu>
        <Button
          className={styles.iconButton}
          variant="outlined"
          onClick={() => dispatch(SetShowInstructions.create(true))}
        >
          <HelpIcon />
        </Button>
        {/* <Button className={styles.iconButton} variant="outlined">
          <SettingsIcon />
        </Button> */}
      </div>
      <AnimatePresence>
        {showInstructions && (
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={styles.overlay}
            onClick={closeInstructions}
          />
        )}
      </AnimatePresence>
      <Instructions key="instructions" onClose={closeInstructions} />
    </>
  );
};

export default Controls;
