import { Card, CardId, Dimensions } from './types';
import { IAppState } from '../store/app';
import { decodePosition, decodeRotation } from './utils';
import { makeHandCardPropsSelector, makeIsDraggingSelector, playerPositionSelector } from '../store/player';
import { useEffect, useMemo, useRef } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

export function useCard(id: CardId) {
  const playerPosition = useSelector<IAppState, number>(playerPositionSelector);
  const handCardPropsSelector = useMemo(makeHandCardPropsSelector, []);
  const handCardProps = useSelector((state: IAppState) => handCardPropsSelector(state, id));
  const isDraggingSelector = useMemo(makeIsDraggingSelector, []);
  const isDragging = useSelector((state: IAppState) => isDraggingSelector(state, id));
  const card = { ...useSelector<IAppState, Card>(state => state.game.cards[id]) };

  const position =
    card.inHand && handCardProps && !isDragging
      ? handCardProps.position
      : decodePosition(card.position, playerPosition);
  const rotation =
    card.inHand && handCardProps && !isDragging
      ? handCardProps.rotation
      : decodeRotation(card.rotation, playerPosition);

  card.position = position;
  card.rotation = rotation;

  return card;
}

export function usePrevious(value: any) {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useSize() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xs'));
  return { isXs, isSm, isMd, isLg, isXl };
}

export function useWindowSize() {
  return useSelector<IAppState, Dimensions | undefined>(state => state.player.windowSize);
}
