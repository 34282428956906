import { CardId } from '../common/types';
import c10 from './img/c10.svg';
import c2 from './img/c2.svg';
import c3 from './img/c3.svg';
import c4 from './img/c4.svg';
import c5 from './img/c5.svg';
import c6 from './img/c6.svg';
import c7 from './img/c7.svg';
import c8 from './img/c8.svg';
import c9 from './img/c9.svg';
import ca from './img/ca.svg';
import cj from './img/cj.svg';
import ck from './img/ck.svg';
import cq from './img/cq.svg';
import d10 from './img/d10.svg';
import d2 from './img/d2.svg';
import d3 from './img/d3.svg';
import d4 from './img/d4.svg';
import d5 from './img/d5.svg';
import d6 from './img/d6.svg';
import d7 from './img/d7.svg';
import d8 from './img/d8.svg';
import d9 from './img/d9.svg';
import da from './img/da.svg';
import dj from './img/dj.svg';
import dk from './img/dk.svg';
import dq from './img/dq.svg';
import h10 from './img/h10.svg';
import h2 from './img/h2.svg';
import h3 from './img/h3.svg';
import h4 from './img/h4.svg';
import h5 from './img/h5.svg';
import h6 from './img/h6.svg';
import h7 from './img/h7.svg';
import h8 from './img/h8.svg';
import h9 from './img/h9.svg';
import ha from './img/ha.svg';
import hj from './img/hj.svg';
import hk from './img/hk.svg';
import hq from './img/hq.svg';
import s10 from './img/s10.svg';
import s2 from './img/s2.svg';
import s3 from './img/s3.svg';
import s4 from './img/s4.svg';
import s5 from './img/s5.svg';
import s6 from './img/s6.svg';
import s7 from './img/s7.svg';
import s8 from './img/s8.svg';
import s9 from './img/s9.svg';
import sa from './img/sa.svg';
import sj from './img/sj.svg';
import sk from './img/sk.svg';
import sq from './img/sq.svg';

export function cardImageForId(id: CardId) {
  if (id === 'c2') return c2;
  if (id === 'c3') return c3;
  if (id === 'c4') return c4;
  if (id === 'c5') return c5;
  if (id === 'c6') return c6;
  if (id === 'c7') return c7;
  if (id === 'c8') return c8;
  if (id === 'c9') return c9;
  if (id === 'c10') return c10;
  if (id === 'cj') return cj;
  if (id === 'cq') return cq;
  if (id === 'ck') return ck;
  if (id === 'ca') return ca;

  if (id === 'd2') return d2;
  if (id === 'd3') return d3;
  if (id === 'd4') return d4;
  if (id === 'd5') return d5;
  if (id === 'd6') return d6;
  if (id === 'd7') return d7;
  if (id === 'd8') return d8;
  if (id === 'd9') return d9;
  if (id === 'd10') return d10;
  if (id === 'dj') return dj;
  if (id === 'dq') return dq;
  if (id === 'dk') return dk;
  if (id === 'da') return da;

  if (id === 'h2') return h2;
  if (id === 'h3') return h3;
  if (id === 'h4') return h4;
  if (id === 'h5') return h5;
  if (id === 'h6') return h6;
  if (id === 'h7') return h7;
  if (id === 'h8') return h8;
  if (id === 'h9') return h9;
  if (id === 'h10') return h10;
  if (id === 'hj') return hj;
  if (id === 'hq') return hq;
  if (id === 'hk') return hk;
  if (id === 'ha') return ha;

  if (id === 's2') return s2;
  if (id === 's3') return s3;
  if (id === 's4') return s4;
  if (id === 's5') return s5;
  if (id === 's6') return s6;
  if (id === 's7') return s7;
  if (id === 's8') return s8;
  if (id === 's9') return s9;
  if (id === 's10') return s10;
  if (id === 'sj') return sj;
  if (id === 'sq') return sq;
  if (id === 'sk') return sk;
  if (id === 'sa') return sa;
}
