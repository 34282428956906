import { IAppState } from '../store/app';
import { PlayerMap } from '../common/types';
import { getColor, getPlayerPositionAndRotation, sortPlayers } from '../common/utils';
import { motion } from 'framer-motion';
import { playerPositionSelector } from '../store/player';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../common/hooks';
import React from 'react';
import classNames from 'classnames';
import styles from './PlayerNames.module.scss';

interface Props {}

const nameWidth = 120;
const nameHeight = 27;

export const PlayerNames: React.FC<Props> = () => {
  useWindowSize();
  const players = useSelector<IAppState, PlayerMap>(state => state.game.players!);
  const playerPosition = useSelector<IAppState, number>(playerPositionSelector);
  const hidePlayerName = useSelector<IAppState, boolean>(state => state.player.hidePlayerName);
  const playerList = Object.keys(players).sort(sortPlayers(players));

  return (
    <div className={styles.playerNames}>
      {playerList.map((playerId, index) => (
        <motion.div
          key={playerId}
          className={classNames(styles.name, { [styles.hidden]: index === playerPosition && hidePlayerName })}
          style={{
            ...getPlayerPositionAndRotation(
              players[playerId].position,
              playerPosition,
              playerList.length,
              nameWidth,
              nameHeight,
              10
            ),
          }}
        >
          <span style={{ color: getColor(players[playerId].position) }}>{players[playerId].name}</span>
        </motion.div>
      ))}
    </div>
  );
};

export default PlayerNames;
