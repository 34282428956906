import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import { MenuItem } from '@material-ui/core';
import { numPlayersSelector } from '../store/game';
import { useSelector } from 'react-redux';
import React from 'react';

interface Props {
  onClose?: () => void;
  alignRight?: boolean;
}

const DealMenu = React.forwardRef((props: any, ref: any) => {
  const { onClose, alignRight } = props;
  const numPlayers = useSelector<IAppState, number>(numPlayersSelector);
  const maxDeal = Math.floor(52 / numPlayers);
  const deal = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].slice(
    0,
    maxDeal
  );

  return (
    <div ref={ref}>
      {deal.map(n => (
        <MenuItem
          key={n}
          style={{ textAlign: alignRight ? 'right' : undefined }}
          onClick={() => {
            FirebaseService.autoDeal(n);
            onClose && onClose();
          }}
        >
          Deal {n}
        </MenuItem>
      ))}
      <MenuItem
        style={{ textAlign: alignRight ? 'right' : undefined }}
        onClick={() => {
          FirebaseService.autoDeal(maxDeal);
          onClose && onClose();
        }}
      >
        Deal All ({maxDeal})
      </MenuItem>
    </div>
  );
});

export default DealMenu;
