import { CircularProgress, IconButton, InputAdornment, InputBase, Typography } from '@material-ui/core';
import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import { numPlayersSelector } from '../store/game';
import { useSelector } from 'react-redux';
import { useSize } from '../common/hooks';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './NameInput.module.scss';

const NameInput: React.FC = () => {
  const numPlayers = useSelector<IAppState, number>(numPlayersSelector);
  const placeholder = `Player ${numPlayers + 1}`;
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { isXs, isSm, isMd, isLg } = useSize();

  function joinGame() {
    setLoading(true);
    FirebaseService.createPlayer(name);
  }

  return (
    <div
      className={classNames(styles.name, {
        [styles.xs]: isXs,
        [styles.sm]: isSm,
        [styles.md]: isMd,
        [styles.lg]: isLg,
      })}
    >
      <div>
        <Typography variant="h2" className={styles.label}>
          What's your name?
        </Typography>
        <InputBase
          className={styles.textField}
          fullWidth
          autoFocus
          autoCorrect="off"
          autoCapitalize="characters"
          spellCheck={false}
          placeholder={placeholder}
          value={name}
          onChange={e => setName(e.target.value.toUpperCase())}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              joinGame();
            }
          }}
          inputProps={{ className: styles.input }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={joinGame} size={isSm ? 'small' : 'medium'}>
                {!loading && <ArrowIcon />}
                {loading && <CircularProgress size={20} />}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </div>
  );
};

export default NameInput;
