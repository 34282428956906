import { Card, CardId, CardMap, CardProperties, PlayerId, PlayerMap, Point, Rect, SelectionBox } from './types';
import { WindowService } from '../services/WindowService';
import { colors } from './constants';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import Randomstring from 'randomstring';
import isEqual from 'lodash.isequal';

export function getRandomSign() {
  return Math.random() > 0.5 ? 1 : -1;
}

export function getRandomInt(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

// create a "selector creator" that uses lodash.isEqual instead of ===
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export function isUndefined(v: any) {
  return v === undefined;
}

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
type Polygon = Point[];
export function doPolygonsIntersect(a: Polygon, b: Polygon) {
  let polygons = [a, b];
  let minA, maxA, projected, i, i1, j, minB, maxB;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    let polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      let i2 = (i1 + 1) % polygon.length;
      let p1 = polygon[i1];
      let p2 = polygon[i2];

      // find the line perpendicular to this edge
      let normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      minA = maxA = undefined;
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y;
        if (minA === undefined || projected < minA) {
          minA = projected;
        }
        if (maxA === undefined || projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = maxB = undefined;
      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y;
        if (minB === undefined || projected < minB) {
          minB = projected;
        }
        if (maxB === undefined || projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA! < minB! || maxB! < minA!) {
        return false;
      }
    }
  }
  return true;
}

export function shuffle(arr: CardId[]): CardId[] {
  const cards = arr.slice();
  for (let i = cards.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    [cards[i], cards[j]] = [cards[j], cards[i]];
  }
  return cards;
}

export function rotateToDeg(rotate: string): number {
  return parseInt(rotate.replace('deg', ''));
}

export function rectToPolygon(rect: SelectionBox | Rect | DOMRect): Polygon {
  const { x, y, height, width } = rect;
  return [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ];
}

export function rectToRotatedPolygon(rect: Rect, deg: number): Polygon {
  const points = rectToPolygon(rect);
  const center = {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2,
  };
  return points.map(corner => {
    return getRotatedPoint(corner, deg, center);
  });
}

export function getRotatedPoint(point: Point, deg: number, center: Point = { x: 0.5, y: 0.5 }): Point {
  const theta = deg * (Math.PI / 180);
  const tempX = point.x - center.x;
  const tempY = point.y - center.y;
  const rotatedX = tempX * Math.cos(theta) - tempY * Math.sin(theta);
  const rotatedY = tempX * Math.sin(theta) + tempY * Math.cos(theta);
  const x = rotatedX + center.x;
  const y = rotatedY + center.y;

  return { x, y };
}

export function relativeToAbsoluteCardPosition(position: Point, width: number, height: number): Point {
  const w = document.documentElement.clientWidth - width;
  const h = document.documentElement.clientHeight - height;

  return { x: position.x * w, y: position.y * h };
}

export function absoluteToRelativeCardPosition(p: Point, width: number, height: number): Point {
  const w = document.documentElement.clientWidth - width;
  const h = document.documentElement.clientHeight - height;

  return { x: p.x / w, y: p.y / h };
}

function clamp(n: number, min: number, max: number): number {
  return Math.round(Math.min(Math.max(n, min), max));
}

export function roundPoint(point: Point): Point {
  return { x: Math.round(point.x), y: Math.round(point.y) };
}

export function pointsAreSame(p1: Point, p2: Point): boolean {
  const rp1 = roundPoint(p1);
  const rp2 = roundPoint(p2);

  return rp1.x === rp2.x && rp1.y === rp2.y;
}

// FYI: @ryan went a little insane while writing this one
export function computeTarget(target: number[], position: Point, velocity: Point, cardWidth: number): Point {
  const w = document.documentElement.clientWidth - (cardWidth + 2); // + 2 for 1px padding
  const h = document.documentElement.clientHeight - (1.4 * cardWidth + 2); //  + 2 for 1px padding

  if (target.length === 2) {
    return { x: clamp(target[0], 0, w), y: clamp(target[1], 0, h) };
  } else if (target.length === 1) {
    if (position.x > w || position.x < 0) {
      return { x: clamp(position.x, 0, w), y: clamp(target[0], 0, h) };
    } else if (position.y > h || position.y < 0) {
      return { x: clamp(target[0], 0, w), y: clamp(position.y, 0, h) };
    } else if (velocity.x === 0) {
      return { x: clamp(position.x, 0, w), y: clamp(target[0], 0, h) };
    } else if (velocity.y === 0) {
      return { x: clamp(target[0], 0, w), y: clamp(position.y, 0, h) };
    } else {
      return { x: clamp(position.x, 0, w), y: clamp(position.y, 0, h) };
    }
  } else {
    return { x: clamp(position.x, 0, w), y: clamp(position.y, 0, h) };
  }
}

export function offsetPoint(point: Point, index: number, length: number, reverse?: boolean, dist = 26): Point {
  const factor = 0.5; // dist / length;

  const offset = {
    x: (reverse ? -1 : 1) * Math.floor(factor * (reverse ? length - index - 1 : index)) + getRandomSign(),
    y: getRandomSign() * getRandomInt(0, 3),
  };

  return { x: point.x + offset.x, y: point.y + offset.y };
}

export function uniq(a: any[]) {
  return a.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}

export function without(a: any[], b: any | any[]) {
  return a.filter(val => (b.indexOf ? b.indexOf(val) === -1 : val !== b));
}

export function getCardRotate(angle: number): number {
  if (angle === 0 || angle === 180) {
    return 0;
  } else {
    return 90;
  }
}

export function encodeRotation(rotate: number, playerPosition: number) {
  const moddedPlayerPosition = playerPosition % 4;
  return rotate - (moddedPlayerPosition > 1 ? 90 : 0);
}

export function encodePosition(position: Point, playerPosition: number) {
  const moddedPlayerPosition = playerPosition % 4;
  const width = WindowService.cardWidth;
  const height = WindowService.cardWidth * 1.4;

  let angle = 0;
  if (moddedPlayerPosition === 0) {
    angle = 0;
  } else if (moddedPlayerPosition === 1) {
    angle = -180;
  } else if (moddedPlayerPosition === 2) {
    angle = -270;
  } else if (moddedPlayerPosition === 3) {
    angle = -90;
  }

  return getRotatedPoint(absoluteToRelativeCardPosition(position, width, height), angle);
}

export function decodeRotation(rotate: number, playerPosition: number) {
  const moddedPlayerPosition = playerPosition % 4;

  switch (moddedPlayerPosition) {
    case 3:
      return rotate + 90;
    case 2:
      return rotate - 90;
    case 1:
      return rotate - 180;
    case 0:
    default:
      return rotate;
  }
  // return rotate + (moddedPlayerPosition === 2 ? 90 : moddedPlayerPosition === 3 ? -90 : 0);
}

export function encodeAngleForPlayerPosition(playerPosition: number) {
  const moddedPlayerPosition = playerPosition % 4;

  switch (moddedPlayerPosition) {
    case 3:
      return -90;
    case 2:
      return 90;
    case 1:
      return 180;
    case 0:
    default:
      return 0;
  }
}

export function decodeAngleForPlayerPosition(playerPosition: number) {
  const moddedPlayerPosition = playerPosition % 4;

  switch (moddedPlayerPosition) {
    case 3:
      return 90;
    case 2:
      return 270;
    case 1:
      return 180;
    case 0:
    default:
      return 0;
  }
}

export function decodePosition(
  position: Point,
  playerPosition: number,
  width = WindowService.cardWidth,
  height = WindowService.cardWidth * 1.4
) {
  let angle = decodeAngleForPlayerPosition(playerPosition);
  return relativeToAbsoluteCardPosition(getRotatedPoint(position, angle), width, height);
}

export function cardToFront(id: CardId, cards: CardId[]): CardId[] {
  return uniq([id].concat(cards));
}

export function sortHand(cards: CardMap) {
  return (a: CardId, b: CardId) => cards[a].inHand!.position - cards[b].inHand!.position;
}

export function sortHandBySuit(cards: CardMap) {
  return (a: CardId, b: CardId) => {
    if (cards[a].id > cards[b].id) {
      return 1;
    } else if (cards[a].id < cards[b].id) {
      return -1;
    } else {
      return 0;
    }
  };
}

export function sortPlayers(players: PlayerMap) {
  return (a: PlayerId, b: PlayerId) => players[a].position - players[b].position;
}

export function cardIsLocked(card: Card, playerId: PlayerId) {
  return (card.selected && card.selected !== playerId) || (card.inHand && card.inHand.playerId !== playerId);
}

export function getPlayerPosition(playerIndex: number, numPlayers: number) {
  const moddedPlayerPosition = playerIndex % 4;
  const fullLoops = Math.floor(numPlayers / 4);
  const partialLoop = numPlayers % 4;
  const loopNumber = Math.floor(playerIndex / 4) + 1;
  if (moddedPlayerPosition === 0) {
    return { x: loopNumber / (fullLoops + (partialLoop > 0 ? 2 : 1)), y: 1 };
  } else if (moddedPlayerPosition === 1) {
    return { x: loopNumber / (fullLoops + (partialLoop > 1 ? 2 : 1)), y: 0 };
  } else if (moddedPlayerPosition === 2) {
    return { x: 0, y: loopNumber / (fullLoops + (partialLoop > 2 ? 2 : 1)) };
  } else {
    // moddedPlayerPosition === 3
    return { x: 1, y: loopNumber / (fullLoops + 1) };
  }
}

export function getPlayerPositionAndRotation(
  playerIndex: number,
  playerPosition: number,
  numPlayers: number,
  width = 0,
  height = 0,
  padding = 0
) {
  let angle = decodeAngleForPlayerPosition(playerPosition);
  const position: Point = getRotatedPoint(getPlayerPosition(playerIndex, numPlayers), angle);
  let adjust = { x: 0, y: 0 };
  let rotate = 0;

  if (position.x === 0) {
    adjust = { x: -width / 2 + height / 2 + 10, y: 0 };
    rotate = 90;
  } else if (position.x === 1) {
    adjust = { x: -width / 2 - height / 2 - 10, y: 0 };
    rotate = -90;
  } else if (position.y === 0) {
    adjust = { x: -width / 2, y: padding };
  } else if (position.y === 1) {
    adjust = { x: -width / 2, y: -height - padding };
  }

  const absolutePosition = relativeToAbsoluteCardPosition(position, 0, 0);
  return { x: absolutePosition.x + adjust.x, y: absolutePosition.y + adjust.y, rotate };
}

function handCardRotation(i: number, total: number) {
  if (total === 1) {
    return 0;
  } else if (total === 2) {
    return i === 0 ? -6 : 6;
  } else if (total < 6) {
    const ANGLE = 8;
    return total === 1 ? 0 : -ANGLE + i * ((2 * ANGLE) / (total - 1));
  } else if (total < 10) {
    const ANGLE = 12;
    return total === 1 ? 0 : -ANGLE + i * ((2 * ANGLE) / (total - 1));
  } else if (total < 20) {
    const ANGLE = 18;
    return total === 1 ? 0 : -ANGLE + i * ((2 * ANGLE) / (total - 1));
  } else {
    const ANGLE = 24;
    return total === 1 ? 0 : -ANGLE + i * ((2 * ANGLE) / (total - 1));
  }
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function addPoints(...args: Point[]): Point {
  return args.reduce(
    (acc, p) => {
      return { x: acc.x + p.x, y: acc.y + p.y };
    },
    { x: 0, y: 0 }
  );
}

export function getHandCardProperties({
  cardId,
  hand,
  playerIndex,
  playerPosition,
  numPlayers,
  cardWidth,
}: {
  cardId: CardId;
  hand: CardId[];
  playerIndex: number;
  playerPosition: number;
  numPlayers: number;
  cardWidth: number;
}): CardProperties {
  const angle = decodeAngleForPlayerPosition(playerPosition);
  const relativePosition = getRotatedPoint(getPlayerPosition(playerIndex, numPlayers), angle);
  const handRotation = handCardRotation(hand.indexOf(cardId), hand.length);
  const cardHeight = cardWidth * 1.4;
  const absolutePosition = relativeToAbsoluteCardPosition(relativePosition, 0, 0);
  const portion = 2 / 3;
  const myPortion = 3 / 4;

  let adjust = { x: 0, y: 0 };
  let centerAdjust = { x: 0, y: 0 };
  let rotationAdjust = 0;
  if (relativePosition.x === 0) {
    adjust = { x: -(1 - portion) * cardHeight, y: -cardWidth / 2 };
    centerAdjust = { x: -3 * cardHeight, y: 0 };
    rotationAdjust = -90;
  } else if (relativePosition.x === 1) {
    adjust = { x: -(1 - portion) * cardHeight, y: -cardWidth / 2 };
    centerAdjust = { x: 3 * cardHeight, y: 0 };
    rotationAdjust = 90;
  } else if (relativePosition.y === 0) {
    adjust = { x: -cardWidth / 2, y: -0.5 * cardHeight };
    centerAdjust = { x: 0, y: -3 * cardHeight };
  } else if (relativePosition.y === 1) {
    adjust = { x: -cardWidth / 2, y: -myPortion * cardHeight };
    centerAdjust = { x: 0, y: 3 * cardHeight };
  }

  const card = addPoints(absolutePosition, adjust);
  const center = addPoints(absolutePosition, adjust, centerAdjust);
  const position = getRotatedPoint(card, handRotation, center);

  return { position, rotation: handRotation + rotationAdjust };
}

export function getHandXPositions(hand: CardId[], cardWidth: number): number[] {
  const angle = decodeAngleForPlayerPosition(0);
  const relativePosition = getRotatedPoint(getPlayerPosition(0, 1), angle);
  const cardHeight = cardWidth * 1.4;

  return hand.map(cardId => {
    const handRotation = handCardRotation(hand.indexOf(cardId), hand.length);
    const absolutePosition = relativeToAbsoluteCardPosition(relativePosition, 0, 0);
    const myPortion = 3 / 4;

    const adjust = { x: -cardWidth / 2, y: -myPortion * cardHeight };
    const centerAdjust = { x: 0, y: 3 * cardHeight };

    const card = addPoints(absolutePosition, adjust);
    const center = addPoints(absolutePosition, adjust, centerAdjust);
    const position = getRotatedPoint(card, handRotation, center);

    return position.x;
  });
}

export function getColor(playerIndex: number) {
  return colors[playerIndex];
}

export function cardHeight(cardWidth: number) {
  return cardWidth * 1.4;
}

export function generateUuid(length = 4): string {
  return Randomstring.generate({
    length,
    capitalization: 'lowercase',
    readable: true,
    charset: 'alphabetic',
  });
}
