import * as Reselect from 'reselect';
import { Card, CardId, CardMap, Dimensions, Player, PlayerId, PlayerMap, Point, SelectionBox } from '../common/types';
import { IAppState } from './app';
import { TypedAction, TypedReducer, setWith } from 'redoodle';
import {
  cardHeight,
  cardIsLocked,
  createDeepEqualSelector,
  getColor,
  getHandCardProperties,
  sortHand,
} from '../common/utils';
import { handsSelector, selectedCardsSelector } from './game';

// model
export interface IPlayerState {
  id?: PlayerId;
  selectionBox?: SelectionBox;
  highlightedCards: Record<CardId, boolean>;
  dragCardId?: CardId;
  dragCardOverHand: boolean;
  cardWidth: number;
  windowSize?: Dimensions;
  hidePlayerName: boolean;
  showInstructions: boolean;
}

// actions
export const SetId = TypedAction.define('APP/PLAYER/SET_ID')<PlayerId>();
export const SetSelectionBox = TypedAction.define('APP/PLAYER/SET_SELECTION_BOX')<SelectionBox | undefined>();
export const UpdateHighlightedCards = TypedAction.define('APP/PLAYER/UPDATE_HIGHLIGHTED_CARDS')<
  Record<CardId, boolean>
>();
export const ResetHighlightedCards = TypedAction.defineWithoutPayload('APP/PLAYER/RESET_HIGHLIGHTED_CARDS')();
export const SetDragPosition = TypedAction.define('APP/PLAYER/SET_DRAG_POSITION')<Point | undefined>();
export const SetDragCardId = TypedAction.define('APP/PLAYER/SET_DRAG_CARD_ID')<CardId | undefined>();
export const SetDragCardOverHand = TypedAction.define('APP/PLAYER/SET_DRAG_CARD_OVER_HAND')<boolean>();
export const SetCardWidth = TypedAction.define('APP/PLAYER/SET_CARD_WIDTH')<number>();
export const SetWindowSize = TypedAction.define('APP/PLAYER/SET_WINDOW_SIZE')<Dimensions>();
export const SetHidePlayerName = TypedAction.define('APP/PLAYER/SET_HIDE_PLAYER_NAME')<boolean>();
export const SetShowInstructions = TypedAction.define('APP/PLAYER/SET_SHOW_INSTRUCTIONS')<boolean>();

// reducer
export const playerReducer: any = TypedReducer.builder<IPlayerState>()
  .withHandler(SetId.TYPE, (state, id) => setWith(state, { id }))
  .withHandler(SetSelectionBox.TYPE, (state, selectionBox) => setWith(state, { selectionBox }))
  .withHandler(ResetHighlightedCards.TYPE, state => setWith(state, { highlightedCards: {} }))
  .withHandler(UpdateHighlightedCards.TYPE, (state, highlightedCards) =>
    setWith(state, { highlightedCards: { ...state.highlightedCards, ...highlightedCards } })
  )
  .withHandler(SetDragCardId.TYPE, (state, dragCardId) => setWith(state, { dragCardId }))
  .withHandler(SetDragCardOverHand.TYPE, (state, dragCardOverHand) => setWith(state, { dragCardOverHand }))
  .withHandler(SetCardWidth.TYPE, (state, cardWidth) => setWith(state, { cardWidth }))
  .withHandler(SetWindowSize.TYPE, (state, windowSize) => setWith(state, { windowSize }))
  .withHandler(SetHidePlayerName.TYPE, (state, hidePlayerName) => setWith(state, { hidePlayerName }))
  .withHandler(SetShowInstructions.TYPE, (state, showInstructions) => setWith(state, { showInstructions }))
  .withDefaultHandler(state => (state ? state : initialPlayerState))
  .build();

// init
export const initialPlayerState: IPlayerState = {
  id: undefined,
  selectionBox: undefined,
  highlightedCards: {},
  dragCardId: undefined,
  dragCardOverHand: false,
  cardWidth: 160,
  windowSize: undefined,
  hidePlayerName: false,
  showInstructions: false,
};

// selector
export const highlightedCardsSelector = Reselect.createSelector(
  (state: IAppState) => state.player.highlightedCards,
  (highlightedCards: Record<CardId, boolean>) => {
    return Object.keys(highlightedCards).filter(cardId => highlightedCards[cardId]);
  }
);

export const cardWidthSelector = Reselect.createSelector(
  (state: IAppState) => state.player.cardWidth,
  (cardWidth: number) => {
    return cardWidth;
  }
);

export const cardDimensionsSelector = Reselect.createSelector(cardWidthSelector, (cardWidth: number) => ({
  width: cardWidth,
  height: cardHeight(cardWidth),
}));

export const playerIdSelector = Reselect.createSelector(
  (state: IAppState) => state.player.id!,
  (playerId: PlayerId) => {
    return playerId;
  }
);

export const playerSelector = Reselect.createSelector(
  playerIdSelector,
  (state: IAppState) => state.game.players,
  (id: PlayerId, players?: PlayerMap) => {
    return players ? players[id] : undefined;
  }
);

export const playerPositionSelector = Reselect.createSelector(playerSelector, (player?: Player) => {
  return player ? player.position : 0;
});

export const dragCardSelector = Reselect.createSelector(
  (state: IAppState) => state.game.cards,
  (state: IAppState) => state.player.dragCardId,
  (cards: CardMap, dragCardId?: CardId) => {
    return dragCardId ? cards[dragCardId] : undefined;
  }
);

export const isDraggingSelector = Reselect.createSelector(dragCardSelector, (card?: Card) => {
  return !!card;
});

export const myHandSelector = Reselect.createSelector(
  playerIdSelector,
  (state: IAppState) => state.game.cards,
  (playerId: PlayerId, cards: CardMap) => {
    return Object.keys(cards)
      .filter(cardId => cards[cardId].inHand?.playerId === playerId)
      .sort(sortHand(cards));
  }
);

export const myHandCountSelector = Reselect.createSelector(myHandSelector, (hand: CardId[]) => {
  return hand.length;
});

export const makeIsDraggingSelector = () =>
  Reselect.createSelector(
    selectedCardsSelector,
    dragCardSelector,
    (_: any, cardId?: CardId) => cardId,
    (selectedCards: CardId[], dragCard?: Card, cardId?: CardId) => {
      return !!dragCard && selectedCards.includes(cardId!) && cardId !== dragCard.id ? true : false;
    }
  );

export const makeIsSelectedSelector = () =>
  Reselect.createSelector(
    playerIdSelector,
    selectedCardsSelector,
    (state: IAppState) => state.game.cards,
    (_: any, cardId: CardId) => cardId,
    (playerId: PlayerId, selectedCards: CardId[], cards: CardMap, cardId: CardId) => {
      return selectedCards.includes(cardId) && cards[cardId].selected === playerId;
    }
  );

export const makeIsHighlightedSelector = () =>
  Reselect.createSelector(
    playerIdSelector,
    highlightedCardsSelector,
    (state: IAppState) => state.game.cards,
    (_: any, cardId: CardId) => cardId,
    (playerId: PlayerId, highlightedCards: CardId[], cards: CardMap, cardId: CardId) => {
      return highlightedCards.includes(cardId);
    }
  );

export const makeIsLockedSelector = () =>
  Reselect.createSelector(
    playerIdSelector,
    (state: IAppState) => state.game.cards,
    (_: any, cardId: CardId) => cardId,
    (playerId: PlayerId, cards: CardMap, cardId: CardId) => {
      return cardIsLocked(cards[cardId], playerId);
    }
  );

export const makeIsOverHandSelector = () =>
  Reselect.createSelector(
    (state: IAppState) => state.player.dragCardOverHand,
    (state: IAppState) => state.player.dragCardId,
    (_: any, cardId: CardId) => cardId,
    (dragCardOverHand: boolean, dragCardId?: CardId, cardId?: CardId) => {
      return dragCardOverHand && dragCardId === cardId;
    }
  );

export const makeColorSelector = () =>
  Reselect.createSelector(
    playerIdSelector,
    (state: IAppState) => state.game.cards,
    (state: IAppState) => state.player.highlightedCards,
    (state: IAppState) => state.game.players!,
    (_: any, cardId: CardId) => cardId!,
    (
      playerId: PlayerId,
      cards: CardMap,
      highlightedCards: Record<CardId, boolean>,
      players: PlayerMap,
      cardId: CardId
    ) => {
      const selectedPlayerId = cards[cardId].selected;
      return selectedPlayerId
        ? getColor(players[selectedPlayerId].position)
        : highlightedCards[cardId]
        ? getColor(players[playerId].position)
        : undefined;
    }
  );

export const makeHandCardPropsSelector = () =>
  createDeepEqualSelector(
    (state: IAppState) => state.game.players!,
    (state: IAppState) => state.game.cards,
    handsSelector,
    (state: IAppState) => state.player.cardWidth,
    playerPositionSelector,
    (_: any, cardId: CardId) => cardId,
    (
      players: PlayerMap,
      cards: CardMap,
      hands: Record<PlayerId, CardId[]>,
      cardWidth: number,
      playerPosition?: number,
      cardId?: CardId
    ) => {
      const playerId = cards[cardId!].inHand?.playerId;

      if (!playerId) return undefined;

      return getHandCardProperties({
        cardId: cardId!,
        hand: hands[playerId],
        playerIndex: players[playerId].position,
        playerPosition: playerPosition!,
        numPlayers: Object.keys(players).length,
        cardWidth,
      });
    }
  );
