import { CardId, Point } from '../common/types';
import { IAppState } from '../store/app';
import { allCards } from '../common/constants';
import { isReadySelector } from '../store/game';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import Card from '../cards/Card';
import ContextMenu from '../game/ContextMenu';
import Hand from './Hand';
import PlayerNames from './PlayerNames';
import React, { MutableRefObject, useRef, useState } from 'react';
import TableSelection from './TableSelection';
import styles from './Table.module.scss';

interface Props {}

export const Table: React.FC<Props> = () => {
  const tableRef = useRef<null | HTMLDivElement>(null);
  const cardRefs = useRef<Record<CardId, MutableRefObject<HTMLDivElement | null>>>({});
  const isReady = useSelector<IAppState, boolean>(isReadySelector);
  const [position, setPosition] = useState<Point | undefined>();

  function onContextMenu(e: any) {
    e.preventDefault();
    // setPosition({ x: e.clientX - 2, y: e.clientY - 4 });
  }

  return isReady ? (
    <motion.div className={styles.table} ref={tableRef} onContextMenu={onContextMenu}>
      <TableSelection tableRef={tableRef} cardRefs={cardRefs.current}>
        {allCards.map((cardId: CardId, index: number) => (
          <Card
            key={cardId}
            id={cardId}
            tableRef={tableRef}
            index={index}
            setCardRef={cardRef => (cardRefs.current[cardId] = cardRef)}
          />
        ))}
      </TableSelection>
      <Hand />
      <PlayerNames />
      <ContextMenu position={position} onClose={() => setPosition(undefined)} />
    </motion.div>
  ) : null;
};

export default Table;
