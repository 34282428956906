import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Luckiest Guy", sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'uppercase',
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiLink: {
      underline: 'none',
    },
  },
});
