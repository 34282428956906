import { IGameState, gameReducer, initialGameState } from './game';
import { IPlayerState, initialPlayerState, playerReducer } from './player';
import { combineReducers } from 'redux';

// model
export interface IAppState {
  game: IGameState;
  player: IPlayerState;
}

// reducer
export const appReducer = combineReducers<IAppState>({
  game: gameReducer,
  player: playerReducer,
});

// init
export const initialAppState = {
  game: initialGameState,
  player: initialPlayerState,
};
