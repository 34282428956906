import * as Reselect from 'reselect';
import { Card, CardId, CardMap, CardPropsUpdate, PlayerId, PlayerMap } from '../common/types';
import { IAppState } from './app';
import { TypedAction, TypedReducer, setWith } from 'redoodle';
import { createDeepEqualSelector, sortHand } from '../common/utils';
import update from 'immutability-helper';

// model
export interface IGameState {
  gameId: string;
  players?: PlayerMap;
  cards: CardMap;
  cardOrder: CardId[];
}

// actions
export const SetGameId = TypedAction.define('APP/MAIN/SET_GAME_ID')<string>();
export const SetPlayers = TypedAction.define('APP/MAIN/SET_PLAYERS')<PlayerMap>();
export const SetCardOrder = TypedAction.define('APP/MAIN/SET_CARD_ORDER')<CardId[]>();
export const SetCards = TypedAction.define('APP/MAIN/SET_CARDS')<CardMap>();
export const UpdateCard = TypedAction.define('APP/MAIN/UPDATE_CARD')<Card>();
export const UpdateCards = TypedAction.define('APP/MAIN/UPDATE_CARDS')<CardMap>();
export const UpdateCardProps = TypedAction.define('APP/MAIN/UPDATE_CARD_PROPS')<CardPropsUpdate>();

// reducer
export const gameReducer: any = TypedReducer.builder<IGameState>()
  .withHandler(SetGameId.TYPE, (state, gameId) => setWith(state, { gameId }))
  .withHandler(SetPlayers.TYPE, (state, players) => setWith(state, { players }))
  .withHandler(SetCards.TYPE, (state, cards) => setWith(state, { cards }))
  .withHandler(SetCardOrder.TYPE, (state, cardOrder) => setWith(state, { cardOrder }))
  .withHandler(UpdateCard.TYPE, (state, card) => update(state, { cards: { $merge: { [card.id]: card } } }))
  .withHandler(UpdateCards.TYPE, (state, cards) => update(state, { cards: { $merge: { ...cards } } }))
  .withHandler(UpdateCardProps.TYPE, (state, cardsUpdate) => {
    const cards = state.cards;
    const updatedCards = cardsUpdate.cards.reduce((acc, cardId) => {
      acc[cardId] = {
        ...cards[cardId],
        ...cardsUpdate.update,
      };
      return acc;
    }, {} as CardMap);
    return update(state, { cards: { $merge: { ...updatedCards } } });
  })
  .withDefaultHandler(state => (state ? state : initialGameState))
  .build();

// init
const gameId = window.location.pathname?.replace('/', '');
export const initialGameState: IGameState = {
  gameId,
  players: undefined,
  cards: {},
  cardOrder: [],
};

// selectors
export const isReadySelector = Reselect.createSelector(
  (state: IAppState) => state.player.id,
  (state: IAppState) => state.game.gameId,
  (state: IAppState) => state.game.players,
  (state: IAppState) => state.game.cards,
  (state: IAppState) => state.game.cardOrder,
  (playerId?: PlayerId, gameId?: string, players?: PlayerMap, cards?: CardMap, cardOrder?: CardId[]) => {
    return (
      !!gameId &&
      !!playerId &&
      !!players &&
      !!players[playerId] &&
      !!cardOrder &&
      !!cards &&
      Object.keys(cards).length === 52
    );
  }
);

export const hasLoadedSelector = Reselect.createSelector(
  (state: IAppState) => state.player.id,
  (state: IAppState) => state.game.gameId,
  (state: IAppState) => state.game.cardOrder,
  (state: IAppState) => state.game.players,
  (playerId?: PlayerId, gameId?: string, cardOrder?: CardId[], players?: PlayerMap) => {
    return !!gameId && !!playerId && !!cardOrder && !!players;
  }
);

export const numPlayersSelector = Reselect.createSelector(
  (state: IAppState) => state.game.players,
  (players?: PlayerMap) => {
    return players ? Object.keys(players).length : 0;
  }
);

export const gameIdSelector = Reselect.createSelector(
  (state: IAppState) => state.game.gameId,
  (gameId: string) => {
    return gameId;
  }
);

export const selectedCardsSelector = createDeepEqualSelector(
  (state: IAppState) => state.game.cards,
  (state: IAppState) => state.game.cardOrder,
  (state: IAppState) => state.player.id,
  (cards: CardMap, cardOrder: CardId[], playerId?: PlayerId) => {
    return cardOrder
      .slice()
      .reverse()
      .filter(cardId => cards[cardId].selected === playerId);
  }
);

export const handsSelector = Reselect.createSelector(
  (state: IAppState) => state.game.cards,
  (cards: CardMap) => {
    const hands = Object.keys(cards).reduce((acc, cardId) => {
      const card = cards[cardId];
      if (card.inHand) {
        if (acc[card.inHand.playerId]) {
          acc[card.inHand.playerId].push(cardId);
        } else {
          acc[card.inHand!.playerId] = [cardId];
        }
      }
      return acc;
    }, {} as Record<PlayerId, CardId[]>);

    Object.keys(hands).forEach(playerId => {
      hands[playerId].sort(sortHand(cards));
    });

    return hands;
  }
);

export const makeZIndexSelector = () =>
  Reselect.createSelector(
    (state: IAppState) => state.game.cardOrder,
    (_: any, cardId: CardId) => cardId,
    (cardOrder: CardId[], cardId: CardId) => {
      return cardOrder.indexOf(cardId);
    }
  );
