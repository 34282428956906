import { Button, CircularProgress, IconButton, InputAdornment, InputBase, Typography } from '@material-ui/core';
import { FirebaseService } from '../services/FirebaseService';
import { SetGameId } from '../store/game';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSize } from '../common/hooks';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import Logo from './logo5.svg';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './Home.module.scss';

interface Props {}

const Home: React.FC<Props> = () => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [joinLoading, setJoinLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { isXs, isSm, isMd, isLg } = useSize();
  const history = useHistory();
  const dispatch = useDispatch();

  async function createGame() {
    const newGameId = await FirebaseService.generateNewGameId();
    FirebaseService.gameId = newGameId;
    dispatch(SetGameId.create(newGameId));
    await FirebaseService.initializeGame();
    history.push(`/${newGameId}`);
  }

  async function joinGame() {
    setJoinLoading(true);
    const hasGame = await FirebaseService.checkGame(code);
    setJoinLoading(false);
    if (hasGame) {
      FirebaseService.gameId = code;
      dispatch(SetGameId.create(code));
      history.push(`/${code}`);
    } else {
      setError('Game not found!');
    }
  }

  function clear() {
    setShowInput(false);
    setCode('');
    setError('');
  }

  return (
    <div
      className={classNames(styles.home, {
        [styles.xs]: isXs,
        [styles.sm]: isSm,
        [styles.md]: isMd,
        [styles.lg]: isLg,
      })}
    >
      <img src={Logo} className={styles.logo} alt="Quarantine Cards Logo" />
      <div className={styles.actions}>
        {!showInput && (
          <>
            <Button className={styles.button} onClick={createGame}>
              {!joinLoading && 'Create Game'}
              {joinLoading && <CircularProgress size={20} />}
            </Button>
            <Button className={styles.button} onClick={() => setShowInput(true)}>
              Join Game
            </Button>
          </>
        )}
        {showInput && (
          <InputBase
            fullWidth
            autoFocus
            autoCorrect="off"
            autoCapitalize="characters"
            spellCheck={false}
            placeholder="GAME ID"
            value={code}
            onChange={e => {
              setError('');
              setCode(e.target.value);
            }}
            onKeyDown={e => {
              if (e.keyCode === 13 && code.length === 4) {
                joinGame();
              }
            }}
            className={styles.textField}
            inputProps={{ className: styles.input, maxLength: 4 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={joinGame} disabled={code.length < 4} size={isSm ? 'small' : 'medium'}>
                  {!joinLoading && <ArrowIcon />}
                  {joinLoading && <CircularProgress size={20} />}
                </IconButton>
                <IconButton onClick={clear} size={isSm ? 'small' : 'medium'}>
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </div>
      <Typography variant="body2" className={styles.error} color="error">
        {error}
      </Typography>
    </div>
  );
};

export default Home;
