import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import { Card, CardId, CardMap, CardUpdate, CardUpdateProperty, GAMES, Player } from '../common/types';
import { IAppState } from '../store/app';
import { SetCardOrder, SetPlayers, UpdateCardProps, UpdateCards, numPlayersSelector } from '../store/game';
import {
  SetId,
  cardDimensionsSelector,
  cardWidthSelector,
  myHandSelector,
  playerIdSelector,
  playerPositionSelector,
} from '../store/player';
import { Store } from 'redux';
import {
  absoluteToRelativeCardPosition,
  encodeAngleForPlayerPosition,
  encodePosition,
  encodeRotation,
  generateUuid,
  getHandCardProperties,
  getPlayerPosition,
  offsetPoint,
  relativeToAbsoluteCardPosition,
  shuffle,
  sleep,
  sortHand,
  sortPlayers,
  uniq,
} from '../common/utils';
import { allCards } from '../common/constants';
import firebase from 'firebase/app';
import isEqual from 'lodash.isequal';
import moment from 'moment';

// const FirebaseService.gameId = window.location.pathname?.replace('/', '');
export function getFirebaseConfig() {
  return {
    apiKey: 'AIzaSyC9QLkPx80tKDXebLSwES9mFLczWp423h4',
    authDomain: 'cards-7a546.firebaseapp.com',
    databaseURL: 'https://cards-7a546.firebaseio.com',
    projectId: 'cards-7a546',
    storageBucket: 'cards-7a546.appspot.com',
    messagingSenderId: '929709464875',
    appId: '1:929709464875:web:cac7d879a02eaac2aef96e',
    measurementId: 'G-ZEPY0QERT2',
  };
}

export class FirebaseService {
  public static instance: FirebaseService;
  public static store: Store<IAppState>;
  public static gameId: string;

  public static signOut() {
    return firebase.auth().signOut();
  }

  public static async signIn() {
    return firebase.auth().signInAnonymously();
  }

  private static getCards(cardOrder: CardId[]) {
    const cardDimensions = cardDimensionsSelector(FirebaseService.store.getState());

    return cardOrder.reduce((acc, cardId, index) => {
      acc[cardId] = {
        id: cardId,
        faceUp: false,
        position: absoluteToRelativeCardPosition(
          offsetPoint(
            relativeToAbsoluteCardPosition({ x: 0.5, y: 0.5 }, cardDimensions.width, cardDimensions.height),
            index,
            allCards.length,
            true
          ),
          cardDimensions.width,
          cardDimensions.height
        ),
        rotation: 0,
      };
      return acc;
    }, {} as Record<CardId, Card>);
  }

  public static resetAllCards() {
    const update: Record<string, any> = {};
    const cardOrder = shuffle(allCards);

    update[`${FirebaseService.gameId}/cards`] = FirebaseService.getCards(cardOrder);
    update[`${FirebaseService.gameId}/cardOrder`] = cardOrder;
    update[`${GAMES}/${FirebaseService.gameId}`] = firebase.database.ServerValue.TIMESTAMP;

    firebase
      .database()
      .ref()
      .update(update);
  }

  public static updateCards(
    cardIds: CardId[],
    cardUpdate: CardUpdate,
    transform?: (value: any, index: number, length: number) => any
  ) {
    // update locally
    FirebaseService.store.dispatch(UpdateCardProps.create({ cards: cardIds, update: cardUpdate }));

    const update = cardIds.reduce((acc, cardId, index) => {
      const transformedCardUpdate = transform ? transform(cardUpdate, index, cardIds.length) : cardUpdate;
      const props = Object.keys(transformedCardUpdate) as CardUpdateProperty[];
      for (const prop of props) {
        acc[`${FirebaseService.gameId}/cards/${cardId}/${prop}`] = transformedCardUpdate[prop];
      }
      return acc;
    }, {} as Record<string, any>);
    update[`${GAMES}/${FirebaseService.gameId}`] = firebase.database.ServerValue.TIMESTAMP;

    firebase
      .database()
      .ref()
      .update(update);
  }

  public static addCardsToHand(cards: CardId[], playerId?: string) {
    const state = FirebaseService.store.getState();
    const playerPosition = playerPositionSelector(state);
    const playerIndex = playerId ? state.game.players![playerId].position : playerPosition;
    const numPlayers = numPlayersSelector(state);
    const cardWidth = cardWidthSelector(state);
    const playerHand = Object.keys(state.game.cards)
      .filter(cardId => state.game.cards[cardId].inHand?.playerId === (playerId || playerIdSelector(state)))
      .sort(sortHand(state.game.cards));
    const hand = uniq(playerHand.concat(cards.slice().reverse()));

    FirebaseService.updateCards(
      hand,
      {
        inHand: { playerId: playerId || playerIdSelector(state), position: 0 },
        faceUp: false,
        position: getPlayerPosition(playerPosition, numPlayers),
      },
      (value, index) => {
        const cardId = hand[index];
        const { position, rotation } = getHandCardProperties({
          cardId,
          hand,
          playerIndex,
          playerPosition,
          numPlayers,
          cardWidth,
        });
        return {
          ...value,
          inHand: { ...value.inHand, position: index },
          faceUp: true,
          position: encodePosition(position, playerPosition),
          rotation: encodeRotation(rotation, playerPosition),
        };
      }
    );
    FirebaseService.moveCardsToTop(hand.slice().reverse());
  }

  public static removeCardsFromHand(cards: CardId[]) {
    const state = FirebaseService.store.getState();
    const hand = myHandSelector(state).filter(cardId => !cards.includes(cardId));
    const playerPosition = playerPositionSelector(state);
    const playerIndex = playerPosition;
    const numPlayers = numPlayersSelector(state);
    const cardWidth = cardWidthSelector(state);

    FirebaseService.updateCards(hand.concat(cards), {}, (value, index) => {
      const cardId = hand[index];
      if (index < hand.length) {
        const { position, rotation } = getHandCardProperties({
          cardId,
          hand,
          playerIndex,
          playerPosition,
          numPlayers,
          cardWidth,
        });
        return {
          inHand: { ...state.game.cards[cardId].inHand, position: index },
          position: encodePosition(position, playerPosition),
          rotation: encodeRotation(rotation, playerPosition),
        };
      } else {
        return {
          inHand: null,
          rotation: encodeAngleForPlayerPosition(playerPosition),
        };
      }
    });
  }

  public static reorderHand(cards: CardId[]) {
    const state = FirebaseService.store.getState();
    const playerPosition = playerPositionSelector(state);
    const playerIndex = playerPosition;
    const playerId = playerIdSelector(state);
    const numPlayers = numPlayersSelector(state);
    const cardWidth = cardWidthSelector(state);
    const hand = cards.slice();

    FirebaseService.updateCards(
      hand,
      {
        inHand: { playerId: playerId, position: 0 },
        position: getPlayerPosition(playerPosition, numPlayers),
      },
      (value, index) => {
        const cardId = hand[index];
        const { position, rotation } = getHandCardProperties({
          cardId,
          hand,
          playerIndex,
          playerPosition,
          numPlayers,
          cardWidth,
        });
        return {
          ...value,
          inHand: { ...value.inHand, position: index },
          position: encodePosition(position, playerPosition),
          rotation: encodeRotation(rotation, playerPosition),
        };
      }
    );
    FirebaseService.moveCardsToTop(hand.slice().reverse());
  }

  public static async autoDeal(n: number) {
    FirebaseService.resetAllCards();
    await sleep(300);

    const cardOrder = FirebaseService.store
      .getState()
      .game.cardOrder.slice()
      .reverse();
    const players = FirebaseService.store.getState().game.players!;
    const playerIds = Object.keys(players).sort(sortPlayers(players));

    let i = 0;
    for (let j = 0; j < n; j++) {
      for (const playerId of playerIds) {
        const cardId = cardOrder[i];
        FirebaseService.addCardsToHand([cardId], playerId);
        i++;
        await sleep(50);
      }
    }

    // const hands = handsSelector(FirebaseService.store.getState());
    // const cards = FirebaseService.store.getState().game.cards;

    // // sort by suit
    // for (const playerId of playerIds) {
    //   const playerHand = hands[playerId];
    //   const sortedPlayerHand = playerHand.slice().sort(sortHandBySuit(cards));
    //   FirebaseService.addCardsToHand(sortedPlayerHand, playerId);
    //   await sleep(50);
    // }
  }

  public static async generateNewGameId() {
    let newGameId = '';
    let value: any = true;
    let iter = 0;

    while (value && iter < 50) {
      value = undefined;
      newGameId = generateUuid();

      const snapshot = await firebase
        .database()
        .ref(`/${newGameId}`)
        .once('value');
      value = snapshot.val();

      iter++;
    }

    return newGameId;
  }

  public static moveCardsToTop(cards: CardId[]) {
    const cardOrder = FirebaseService.store.getState().game.cardOrder.slice();
    const indices = cards.reduce((acc, cardId) => {
      acc[cardId] = cardOrder.indexOf(cardId);
      return acc;
    }, {} as Record<CardId, number>);

    Object.keys(indices)
      .sort((a: CardId, b: CardId) => {
        return indices[a] - indices[b];
      })
      .forEach((cardId: CardId, i: number) => {
        cardOrder.splice(indices[cardId] - i, 1);
      });

    const newCardOrder = cardOrder.concat(cards.slice().reverse());

    // update locally
    FirebaseService.store.dispatch(SetCardOrder.create(newCardOrder));

    firebase
      .database()
      .ref(`${FirebaseService.gameId}/cardOrder`)
      .set(newCardOrder);
  }

  public static async addPlayer(player: Player) {
    firebase
      .database()
      .ref(`${FirebaseService.gameId}/players/${player.id}`)
      .update(player);
  }

  public static async createPlayer(name?: string) {
    const state = FirebaseService.store.getState();
    const players = state.game.players || {};
    const playerId = playerIdSelector(state);
    const newPlayerPosition = numPlayersSelector(state);
    const newPlayer = FirebaseService.getNewPlayer(playerId, newPlayerPosition, name);

    // add to local store
    players[playerId] = newPlayer;
    FirebaseService.store.dispatch(SetPlayers.create(players));

    // update remote store
    FirebaseService.addPlayer(newPlayer);

    // track
    firebase.analytics().logEvent('game_joined');
  }

  public static async initializeGame() {
    const players = {};
    const cardOrder = shuffle(allCards);
    const cards = FirebaseService.getCards(cardOrder);

    const update = {
      [`${FirebaseService.gameId}`]: { id: FirebaseService.gameId, players, cards, cardOrder },
      [`${GAMES}/${FirebaseService.gameId}`]: firebase.database.ServerValue.TIMESTAMP,
    };

    // track
    firebase.analytics().logEvent('game_created');

    return firebase
      .database()
      .ref()
      .update(update);
  }

  private static getNewPlayer(id: string, position: number, name?: string): Player {
    return {
      id,
      name: name || `Player ${position + 1}`,
      position,
    };
  }

  private static async cleanupStaleGames() {
    const snapshot = await firebase
      .database()
      .ref(`/${GAMES}`)
      .once('value');
    const games = snapshot.val();

    const twentyFourHoursAgo = moment().subtract('24', 'hours');
    const update = Object.keys(games)
      .filter(gameId => moment(games[gameId]).isBefore(twentyFourHoursAgo))
      .reduce((acc, gameId) => {
        acc[gameId] = null;
        acc[`${GAMES}/${gameId}`] = null;
        return acc;
      }, {} as Record<string, null>);

    firebase
      .database()
      .ref()
      .update(update);
  }

  private static listenForPlayerUpdates() {
    firebase
      .database()
      .ref(`/${FirebaseService.gameId}/players`)
      .on('value', snapshot => {
        const players = snapshot.val() || {};
        FirebaseService.store.dispatch(SetPlayers.create(players));
      });
  }

  private static listenForCardOrderUpdates() {
    firebase
      .database()
      .ref(`/${FirebaseService.gameId}/cardOrder`)
      .on('value', snapshot => {
        const cardOrder = snapshot.val();
        FirebaseService.store.dispatch(SetCardOrder.create(cardOrder));
      });
  }

  private static listenForCardUpdates() {
    firebase
      .database()
      .ref(`/${FirebaseService.gameId}/cards`)
      .on('value', snapshot => {
        const cards = snapshot.val();
        const prevCards = FirebaseService.store.getState().game.cards;
        const cardsToUpdate = Object.keys(cards)
          .filter(cardId => !isEqual(cards[cardId], prevCards[cardId]))
          .reduce((acc, cardId) => {
            acc[cardId] = cards[cardId];
            return acc;
          }, {} as CardMap);

        FirebaseService.store.dispatch(UpdateCards.create(cardsToUpdate));
      });
  }

  public static startGame() {
    if (FirebaseService.gameId && FirebaseService.store.getState().player.id) {
      FirebaseService.listenForPlayerUpdates();
      FirebaseService.listenForCardOrderUpdates();
      FirebaseService.listenForCardUpdates();
    }
  }

  public static async checkGame(id: string) {
    const snapshot = await firebase
      .database()
      .ref(`/${id}`)
      .once('value');
    return !!snapshot.val();
  }

  public static init(store: Store) {
    this.instance = new FirebaseService(store);
    FirebaseService.gameId = window.location.pathname?.replace('/', '');
    FirebaseService.signIn();
    FirebaseService.listenToAuthChanges();
    FirebaseService.cleanupStaleGames();
  }

  private static listenToAuthChanges() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        FirebaseService.store.dispatch(SetId.create(user.uid));
      }
    });
  }

  private constructor(store: Store) {
    FirebaseService.store = store;
    firebase.initializeApp(getFirebaseConfig());
    firebase.analytics();
    FirebaseService.instance = this;
  }
}
