import { CardId, PlayerId, Point } from '../common/types';
import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import {
  SetDragCardId,
  SetDragCardOverHand,
  makeColorSelector,
  makeIsDraggingSelector,
  makeIsHighlightedSelector,
  makeIsLockedSelector,
  makeIsOverHandSelector,
  myHandSelector,
  playerIdSelector,
  playerPositionSelector,
} from '../store/player';
import { UpdateCardProps, makeZIndexSelector, numPlayersSelector, selectedCardsSelector } from '../store/game';
import {
  cardHeight,
  cardToFront,
  computeTarget,
  doPolygonsIntersect,
  encodeAngleForPlayerPosition,
  encodePosition,
  getHandXPositions,
  offsetPoint,
  rectToPolygon,
  rectToRotatedPolygon,
  without,
} from '../common/utils';
import { cardImageForId } from './images';
import { getHandRect } from '../table/Hand';
import { motion } from 'framer-motion';
import { useCard } from '../common/hooks';
import { useDispatch, useSelector, useStore } from 'react-redux';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import arrayMove from 'array-move';
import back from './img/back.svg';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface Props {
  id: CardId;
  tableRef: MutableRefObject<null | HTMLDivElement>;
  index: number;
  setCardRef: (cardRef: MutableRefObject<HTMLDivElement | null>) => void;
}

export const Card: React.FC<Props> = ({ id, tableRef, setCardRef }) => {
  let target: number[] = [];
  const cardRef = useRef<HTMLDivElement | null>(null);
  setCardRef(cardRef);

  const dispatch = useDispatch();
  const store = useStore();
  const card = useCard(id);
  const cardWidth = useSelector<IAppState, number>(state => state.player.cardWidth);
  const playerId = useSelector<IAppState, PlayerId>(playerIdSelector);
  const playerPosition = useSelector<IAppState, number>(playerPositionSelector);
  const isDraggingSelector = useMemo(makeIsDraggingSelector, []);
  const isDragging = useSelector((state: IAppState) => isDraggingSelector(state, id));
  const isHighlightedSelector = useMemo(makeIsHighlightedSelector, []);
  const isHighlighted = useSelector((state: IAppState) => isHighlightedSelector(state, id));
  const isLockedSelector = useMemo(makeIsLockedSelector, []);
  const isLocked = useSelector((state: IAppState) => isLockedSelector(state, id));
  const zIndexSelector = useMemo(makeZIndexSelector, []);
  const zIndex = useSelector((state: IAppState) => zIndexSelector(state, id));
  const isOverHandSelector = useMemo(makeIsOverHandSelector, []);
  const isOverHand = useSelector((state: IAppState) => isOverHandSelector(state, id));
  const colorSelector = useMemo(makeColorSelector, []);
  const color = useSelector((state: IAppState) => colorSelector(state, id));
  const [dragging, setDragging] = useState<boolean>(false);
  const [flipping, setFlipping] = useState<boolean>(false);

  const isSelected = card.selected === playerId;
  const inMyHand = card.inHand?.playerId === playerId;
  const inOtherHand = card.inHand && card.inHand.playerId !== playerId;
  const position = card.position;
  const rotate = card.rotation;

  return (
    <motion.div
      ref={cardRef}
      animate={!dragging ? { ...position } : undefined}
      transition={
        !dragging && !(isSelected && isDragging) ? { type: 'tween', ease: [0.25, 1, 0.5, 1], duration: 0.8 } : undefined
      }
      drag
      dragConstraints={card.inHand ? undefined : tableRef}
      dragTransition={{
        power: 0.2,
        timeConstant: 150,
        modifyTarget: (t: number) => {
          target.push(t);
          return t;
        },
      }}
      onDragStart={() => {
        setDragging(true);

        const selectedCards = selectedCardsSelector(store.getState());
        const cards = cardToFront(id, selectedCards);
        if (!isSelected && selectedCards.length) {
          FirebaseService.updateCards(cardToFront(id, selectedCards), { selected: null }, (value, index) =>
            index === 0 ? { selected: playerId } : value
          );
        }

        if (inMyHand) {
          dispatch(
            UpdateCardProps.create({ cards, update: { rotation: encodeAngleForPlayerPosition(playerPosition) } })
          );
        }

        FirebaseService.moveCardsToTop(cardToFront(id, selectedCards));
        dispatch(SetDragCardId.create(id));
        target = [];
      }}
      onDrag={(e: any, panInfo) => {
        const isIntersecting = doPolygonsIntersect(
          rectToPolygon(getHandRect(playerPosition, numPlayersSelector(store.getState()))),
          rectToRotatedPolygon({ ...panInfo.point, width: cardWidth, height: cardHeight(cardWidth) }, rotate)
        );
        if (!isOverHand && isIntersecting) dispatch(SetDragCardOverHand.create(true));
        if (isOverHand && !isIntersecting) dispatch(SetDragCardOverHand.create(false));

        // reorder hand
        if (inMyHand && isOverHand && panInfo?.point) {
          const hand = myHandSelector(store.getState()).slice();
          const handXPositions = getHandXPositions(hand, cardWidth);
          const curPos = card.inHand!.position;
          const x = panInfo.point.x;

          let newPos;
          if (x < handXPositions[0]) {
            newPos = 0;
          } else if (x > handXPositions[hand.length - 1]) {
            newPos = hand.length - 1;
          } else {
            newPos = handXPositions.findIndex((xPosition, index, arr) => x > xPosition && x < arr[index + 1]);
          }

          if (newPos !== curPos) {
            FirebaseService.reorderHand(arrayMove(hand, curPos, newPos));
          }
        }

        const selectedCards = selectedCardsSelector(store.getState());
        const dragCards = without(selectedCards, id);
        if (dragCards.length) {
          dispatch(
            UpdateCardProps.create({
              cards: dragCards,
              update: { position: encodePosition(panInfo.point, playerPosition) },
            })
          );
        }
      }}
      onDragEnd={(_, panInfo) => {
        setDragging(false);
        const newTarget = computeTarget(target, panInfo.point, panInfo.velocity, cardWidth);

        // update all selected cards with target position
        const selectedCards = selectedCardsSelector(store.getState());
        FirebaseService.updateCards(
          cardToFront(id, selectedCards),
          { position: newTarget },
          (value: { position: Point }, index, length) => {
            return {
              position: encodePosition(offsetPoint(value.position, index, length, true), playerPosition),
            };
          }
        );

        dispatch(SetDragCardId.create(undefined));
      }}
      className={classNames(styles.card, {
        [styles.dragging]: dragging,
        [styles.preventInteraction]: isLocked,
      })}
      whileHover={inMyHand ? { scale: 1.05 } : { scale: 1 }}
      onClick={e => {
        if (e.shiftKey) {
          FirebaseService.updateCards([id], { selected: !card.selected ? playerId : null });
        }
      }}
      onDoubleClick={() => {
        setFlipping(true);
        setTimeout(() => setFlipping(false), 800);
        FirebaseService.updateCards([id], { faceUp: !card.faceUp });
      }}
      style={{ zIndex: 10 + zIndex || 0 }}
    >
      <motion.div
        className={classNames(styles.cardOuter, {
          [styles.selected]: (card.selected || isHighlighted) && !flipping,
        })}
        style={{ boxShadow: card.selected || isHighlighted ? `0 0 0 3px ${color}` : undefined }}
        animate={{ rotate }}
      >
        <div className={classNames(styles.cardInner, { [styles.faceUp]: card.faceUp && !inOtherHand })}>
          <div className={styles.back}>
            <img
              src={back}
              className={classNames(styles.image, { [styles.dragging]: dragging })}
              alt=""
              draggable={false}
            />
          </div>
          <div className={styles.front}>
            <img
              src={cardImageForId(id)}
              className={classNames(styles.image, { [styles.dragging]: dragging })}
              alt=""
              draggable={false}
            />
            <div
              className={classNames({ [styles.highlighted]: isHighlighted || card.selected })}
              style={{ backgroundColor: color }}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Card;
