import { IAppState } from '../store/app';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dialog from './Dialog';
import FlipVid from './vid/flip.mov';
import React from 'react';
import RotateVid from './vid/rotate.mov';
import SelectBoxVid from './vid/select-box.mov';
import SelectVid from './vid/select.mov';
import classNames from 'classnames';
import styles from './Instructions.module.scss';

interface Props {
  onClose: () => void;
}

const Instructions: React.FC<Props> = ({ onClose }) => {
  const showInstructions = useSelector<IAppState, boolean>(state => state.player.showInstructions);
  const isSm = useMediaQuery('(max-width:500px)');

  return (
    <Dialog title="How To Play" isOpen={showInstructions} onClose={onClose}>
      <div className={classNames(styles.container, { [styles.small]: isSm })}>
        <div className={styles.command}>
          <div className={styles.videoWrapper}>
            <video autoPlay loop src={FlipVid} className={styles.video} />
          </div>
          <div className={styles.keys}>
            <div className={styles.keyWord}>DOUBLE CLICK</div>
          </div>
        </div>
        <div className={styles.command}>
          <div className={styles.videoWrapper}>
            <video autoPlay loop src={SelectVid} className={styles.video} />
          </div>
          <div className={styles.keys}>
            <div className={styles.key}>SHIFT</div>
            <div className={styles.plus}>+</div>
            <div className={styles.keyWord}>CLICK</div>
          </div>
        </div>
        <div className={styles.command}>
          <div className={styles.videoWrapper}>
            <video autoPlay loop src={SelectBoxVid} className={styles.video} />
          </div>
          <div className={styles.keys}>
            <div className={styles.key}>SHIFT</div>
            <div className={styles.plus}>+</div>
            <div className={styles.keyWord}>DRAG</div>
          </div>
        </div>
        <div className={styles.command}>
          <div className={styles.videoWrapper}>
            <video autoPlay loop src={RotateVid} className={styles.video} />
          </div>
          <div className={styles.keys}>
            <div className={styles.keyWord}>DRAG</div>
            <div className={styles.plus}>+</div>
            <div className={styles.key}>TAB</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Instructions;
