import { FirebaseService } from '../services/FirebaseService';
import { IAppState } from '../store/app';
import { Player, PlayerId, RouteParams } from '../common/types';
import { SetGameId, hasLoadedSelector, isReadySelector } from '../store/game';
import { playerIdSelector, playerSelector } from '../store/player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Controls from './Controls';
import Loading from './Loading';
import NameInput from '../home/NameInput';
import React, { useEffect } from 'react';
import Table from '../table/Table';
import styles from './Game.module.scss';

const Game: React.FC = () => {
  const player = useSelector<IAppState, Player | undefined>(playerSelector);
  const playerId = useSelector<IAppState, PlayerId | undefined>(playerIdSelector);
  const hasLoaded = useSelector<IAppState, boolean>(hasLoadedSelector);
  const isReady = useSelector<IAppState, boolean>(isReadySelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch<RouteParams>();
  const { gameid } = routeMatch.params;

  useEffect(() => {
    async function check(gameId: string) {
      if (await FirebaseService.checkGame(gameId)) {
        FirebaseService.startGame();
      } else {
        dispatch(SetGameId.create(''));
        FirebaseService.gameId = '';
        history.replace('/');
      }
    }
    check(gameid);
  }, [dispatch, gameid, history, playerId]);

  if (!hasLoaded) {
    return <Loading />;
  } else if (!player) {
    return <NameInput />;
  } else if (!isReady) {
    return <Loading />;
  } else {
    return (
      <div className={styles.game}>
        <Table />
        <Controls />
      </div>
    );
  }
};

export default Game;
