import { AnimatePresence, motion } from 'framer-motion';
import { CardId, SelectionBox } from '../common/types';
import { IAppState } from '../store/app';
import { highlightedCardsSelector } from '../store/player';
import { useSelector } from 'react-redux';
import React from 'react';
import styles from './CardCount.module.scss';

interface Props {}

export const CardCount: React.FC<Props> = () => {
  const highlightedCards = useSelector<IAppState, CardId[]>(highlightedCardsSelector);
  const selectionBox = useSelector<IAppState, SelectionBox | undefined>(state => state.player.selectionBox);

  return (
    <AnimatePresence>
      {selectionBox && highlightedCards.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ x: selectionBox.mouseX, y: selectionBox.mouseY }}
          className={styles.count}
        >
          {highlightedCards.length}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CardCount;
